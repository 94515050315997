import React, { useState, useEffect } from "react";
import { FaPlusCircle, FaTachometerAlt } from "react-icons/fa";
import CounterSlice from "./CounterSlice";
import Canvas from "./Canvas";
import CounterWhole from "./CounterWhole";

function Pizza({ food, indexF, list, orderedList, setOrderedList }) {
  // FOOD IS THE SELECTED PIZZA.
  let [showDetails, setShowDetails] = useState();
  let [isIncreased, setIsIncreased] = useState(false);
  let [showExtras, setShowExtras] = useState(false);
  let [showSides, setShowSides] = useState(false);
  let [countExtra, setCountExtra] = useState(15);
  let [countSide, setCountSide] = useState(6);
  let [slicePrice, setSlicePrice] = useState(0);
  let [wholePrice, setWholePrice] = useState(0);
  let [selectedSide, setSelectedSide] = useState([]);
  let [selectedExtra, setSelectedExtra] = useState([]);
  let [pizzaSum, setPizzaSum] = useState(0);
  let [counterSlice, setCounterSlice] = useState(0);
  let [counterWhole, setCounterWhole] = useState(0);
  let [description, setDescription] = useState("");
  // "extraState" => THE EXTRAS OF THE SELECTED PIZZA //
  let [extraState, setExtraState] = useState(list[indexF].extras);
  // "sideState" =>  THE SIDES OF THE SELECTED PIZZA //
  let [sideState, setSideState] = useState([]);

  useEffect(() => {
    //SHOW AND HIDE THE BODY SCROLLBAR WHEN THE MODAL IS OPEN//
    if (showDetails) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showDetails]);
  useEffect(() => {
    // ADDING SELECT KEY TO THE "extraState" IN ORDER TO MARK THE SELECTED ITEM //

    food.type === "pizza" &&
      setExtraState(
        extraState.map((extra) => ({
          select: false,
          name: extra.name,
          price: extra.price,
        }))
      );

    food.type === "pizza" &&
      setSideState(
        food.sides.map((side) => ({
          select: false,
          name: side.name,
          price: side.price,
        }))
      );
  }, []);
  let onExtraChange = (e, extIndx, price) => {
    // MARKING THE EXTRA-ITEM IN ORDER TO GIVE FOR THE SHOPPING-CART. (checked event!) //
    let newExtraState = [...extraState];

    if (e.target.checked === true) {
      newExtraState[extIndx].select = true;
      setExtraState(newExtraState);
      setCountExtra(countExtra - 1);

      setPizzaSum(pizzaSum + price);
    } else {
      newExtraState[extIndx].select = false;
      setExtraState(newExtraState);
      setCountExtra(countExtra + 1);
      setPizzaSum(pizzaSum - price);
    }
  };

  let onSideChange = (e, sideIndx, price) => {
    // MARKING THE SIDE-ITEM IN ORDER TO GIVE FOR THE SHOPPING-CART. (checked event!) //
    let newSideState = [...sideState];
    if (e.target.checked === true) {
      newSideState[sideIndx].select = true;
      setSideState(newSideState);
      setCountSide(countSide - 1);
      setPizzaSum(pizzaSum + price);
    } else {
      newSideState[sideIndx].select = false;
      setSideState(newSideState);
      setCountSide(countSide + 1);
      setPizzaSum(pizzaSum - price);
    }
  };
  let AddToCart = (pName, pPrice) => {
    selectedExtra.length = 0;

    selectedSide.length = 0;
    let newSelectedExtra = [...selectedExtra];

    extraState.map((extra) =>
      extra.select === true ? newSelectedExtra.push(extra) : extra.select
    );
    setSelectedExtra(newSelectedExtra);

    let newSelectedSide = [...selectedSide];
    sideState.map((side) =>
      side.select === true ? newSelectedSide.push(side) : side.select
    );
    setSelectedSide(newSelectedSide);

    let allowed = false;

    let newCart = {
      id: Math.round(Math.random() * 10000),
      name: pName,
      price: pPrice,
      slicePrice: slicePrice,
      extras: newSelectedExtra,
      sides: newSelectedSide,
      counterWhole: counterWhole,
      counterSlice: counterSlice,
      description: description,
      sumPrice: pizzaSum,
    };

    let newOrderedList = [...orderedList];
    //ONLY EXTRA TOPPING NOT ALLOWED TO ORDER SO WE MISS TO PUSH TO THE CART//

    try {
      if (newCart.extras.length !== 0 && newCart.counterWhole === 0) {
        throw "Csak extra fetétet külön nem tudsz rendelni";
      } else {
        newOrderedList.push(newCart);
      }
    } catch (error) {
      alert(error);
    }

    setOrderedList(newOrderedList);
    setShowDetails(false);
  };

  let resetOrder = () => {
    setShowDetails(false);
    // window.location.reload();
  };

  let getDetails = () => {
    setCounterSlice(0);
    setCounterWhole(0);
    setSlicePrice(0);
    setPizzaSum(0);
    setShowDetails(true);
  };

  let getExtras = () => {
    !showExtras ? setShowExtras(true) : setShowExtras(false);
  };
  let getSides = () => {
    !showSides ? setShowSides(true) : setShowSides(false);
  };

  return (
    <div className="p-d-flex p-flex-row p-jc-center p-ai-center">
      {food.type === "pizza" ? (
        <div
          id="pizzaBar"
          className="p-d-flex p-shadow-3 p-jc-between p-ai-center "
          key={indexF}
        >
          <div className="p-d-flex p-ai-center">
            <FaPlusCircle
              style={{ marginRight: "2rem", cursor: "pointer" }}
              onClick={getDetails}
            />
            <p id="pizzaName">{food.name}</p>
            <p id="pizzaIngredients">
              <em>{food.ingredients.join(", ")}</em>
            </p>
          </div>
          <p id="pizzaPriceSum">{food.price},- Ft</p>
        </div>
      ) : (
        <div />
      )}
      {showDetails && (
        <div className="modal-bg">
          <div className="close-modal" onClick={resetOrder}>
            X
          </div>
          <div
            className=" modal-content p-d-flex p-flex-column p-shadow-3"
            style={{ backgroundColor: "white" }}
          >
            <div
              className="detail-header"
              style={{
                backgroundImage: `url(/img/${food.picture})`,
                position: "center",
              }}
            />
            <div className="p-d-flex p-flex-column p-ai-start p-ml-3">
              <p id="pizzaName">
                {food.name}
                <br />
                <span style={{ color: "grey", fontSize: "1rem" }}>
                  ({food.ingredients.join(", ")})
                </span>
              </p>
            </div>
            <div className="p-d-flex p-ai-center p-jc-around">
              <div
                className="p-d-flex p-flex-column p-jc-around"
                style={{ height: "10vh" }}
              >
                <p id="quantity">Egész:</p>
                <p id="quantity">Szelet:</p>
              </div>
              <div
                className="p-d-flex p-flex-column p-jc-around"
                style={{ height: "10vh" }}
              >
                <CounterWhole
                  setWholePrice={setWholePrice}
                  setCounterWhole={setCounterWhole}
                  wholePrice={wholePrice}
                  counterWhole={counterWhole}
                  foodPrice={food.price}
                  pizzaSum={pizzaSum}
                  setPizzaSum={setPizzaSum}
                />
                <CounterSlice
                  setIsIncreased={setIsIncreased}
                  setSlicePrice={setSlicePrice}
                  slicePrice={slicePrice}
                  setCounterSlice={setCounterSlice}
                  counterSlice={counterSlice}
                  foodPrice={food.price}
                  pizzaSum={pizzaSum}
                  setPizzaSum={setPizzaSum}
                />
              </div>
              <div
                className="p-d-flex p-flex-column p-jc-around p-ai-center"
                style={{ height: "10vh" }}
              >
                <div id="pizzaWholeFigure">
                  <p id="counterWhole">{counterWhole}</p>
                </div>
                <Canvas
                  className="pizzaSliceFigure"
                  counterSlice={counterSlice}
                  isIncreased={isIncreased}
                />
              </div>
              <div
                className="p-d-flex p-flex-column p-ai-center p-jc-around"
                style={{ height: "10vh" }}
              >
                <p id="pPrice">Ár: {food.price * counterWhole} Ft</p>
                <p id="pPrice">Ár: {slicePrice} Ft</p>
              </div>
            </div>
            <div
              className="p-d-flex p-shadow-2 p-p-1 p-jc-around"
              style={{
                width: "100%",
              }}
              onClick={getExtras}
            >
              <p className="extraTopping">Extra feltét pizzára</p>
              <p className="chooseTopping">
                Válassz akár <b>{countExtra}</b> további tételt
              </p>
            </div>
            {showExtras && (
              <div style={{ backgroundColor: "whitesmoke" }} className="p-p-4">
                {food.extras.map((extra, indexExt) => (
                  <div
                    key={indexExt}
                    className="p-d-flex p-ai-center p-jc-between"
                    style={{ width: "100%" }}
                  >
                    <div className="p-d-flex p-ai-center">
                      <input
                        type="checkbox"
                        style={{ zoom: "1.5" }}
                        onChange={(e) =>
                          onExtraChange(e, indexExt, extra.price)
                        }
                        checked={extra.select}
                      />

                      <p id="extras">{extra.name}</p>
                    </div>
                    <div>
                      <p id="extrPrice"> + {extra.price} Ft</p>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div
              className="p-d-flex p-shadow-2 p-jc-around"
              style={{
                width: "100%",
              }}
              onClick={getSides}
            >
              {showSides ? (
                <p className="extraTopping">Extrák pizza mellé</p>
              ) : (
                <p className="extraTopping">Extrák pizza mellé</p>
              )}
              <p className="chooseTopping">
                Válassz akár <b>{countSide}</b> további tételt
              </p>
            </div>
            {showSides && (
              <div style={{ backgroundColor: "whitesmoke" }} className="p-p-4">
                {sideState.map((side, index) => (
                  <div
                    key={index}
                    className="p-d-flex p-ai-center p-jc-between"
                    style={{ width: "100%" }}
                  >
                    <div className="p-d-flex p-ai-center">
                      <input
                        type="checkbox"
                        style={{ zoom: "1.5" }}
                        onChange={(e) => onSideChange(e, index, side.price)}
                        checked={side.select}
                      />
                      <p id="extras">{side.name}</p>
                    </div>
                    <div>
                      <p id="extrPrice"> + {side.price} Ft</p>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="p-d-flex p-flex-column p-p-4">
              <p style={{ fontSize: "1rem" }}>
                <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                  Speciális útmutatás
                </span>
                <br />
                <span style={{ color: "grey" }}>
                  Ide leírhatsz bármilyen speciális útmutatást
                </span>
              </p>
              <textarea
                rows="6"
                col="60"
                placeholder="pl.: Paradicsom nélkül"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                style={{ outline: "none", borderColor: "grey" }}
              />
            </div>
            <div className="pizzaSumExtra p-d-flex p-jc-around">
              <p>Teljes összeg:</p>
              <p>
                <b> {pizzaSum} Ft</b>
              </p>
            </div>

            <div className="p-m-5 p-d-flex p-jc-center">
              <button
                className="buttonStyle"
                onClick={() => AddToCart(food.name, food.price)}
                id="addToCart"
              >
                TEDD A KOSÁRBA
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Pizza;
