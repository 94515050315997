import React from "react";
import Navbar from "./NavBar";
import Footer from "./Footer";
import {
  FaLinkedin,
  FaWhatsapp,
  FaGithub,
  FaPhoneAlt,
  FaEnvelope,
} from "react-icons/fa";

function AboutUs() {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Navbar />
      <div
        className="p-d-flex p-jc-center p-ai-center p-p-5"
        style={{ width: "100%", height: "100%", marginTop: "6%" }}
      >
        <div className="profile-container p-d-flex p-flex-column p-jc-center p-ai-center">
          <p className="welcome-message">DÉR ÁDÁM</p>
          <p id="welcome-message">
            Junior Front End fejlesztő és
            <p style={{ textAlign: "center" }}>Web Designer</p>
          </p>
        </div>
        <div className="profile-img-adam"></div>
        <div
          style={{
            width: "100%",
            height: "100vh",
            backgroundColor: "white",
          }}
        >
          <div className="box-1">
            <p id="introducing-txt">
              Gimnáziumban kezdtem foglalkozni a programozással HTML és CSS
              alapokkal majd a CodeCoolnál megcsíptem egy ösztöndíjas Front-End
              fejlesztői képzést ahol a következő technológiákkal dolgoztam a
              különböző solo- és csapatprojekteken keresztül:
            </p>
            <ul id="technologies">
              <li>HTML5/CSS3/SASS</li>
              <li>VanillaJs/ReactJs</li>
              <li>Node.Js/Express.Js</li>
              <li>GitHub/Docker</li>
              <li>PrimeReact/BootStrap</li>
            </ul>
            <p id="introducing-txt2">
              Célom, hogy fejlesszem magam, problémákat oldjak meg egy jól
              működő csapat tagjaként, másoktól tanulva kreatív és
              újrafelhasználható WEB applikációkat hozzak létre.
            </p>
          </div>
          <div className="box-2">
            <div
              className="p-d-flex p-flex-column p-jc-end p-ai-center"
              style={{ width: "100%", height: "100%" }}
            >
              <p className="my-contacts" style={{ marginLeft: "5%" }}>
                Kapcsolat
              </p>
              <ul className="contact-list">
                <li>
                  <FaPhoneAlt id="phone-icon" />
                  <FaWhatsapp id="whatsapp-icon" /> +36204116443
                </li>
                <li>
                  <FaEnvelope id="email-icon" /> deradam1@gmail.com
                </li>
              </ul>
            </div>
            <div
              className="p-d-flex p-jc-center p-ai-center"
              style={{ width: "100%", height: "100%" }}
            >
              <ul className="link-list">
                <li>
                  <a href="https://www.linkedin.com/in/adam-der-098613209">
                    <FaLinkedin style={{ fontSize: "2rem" }} />
                  </a>
                </li>
                <li>
                  <a href="https://github.com/deradam-dotcom?tab=repositories">
                    {" "}
                    <FaGithub id="github-icon" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        className="p-d-flex p-jc-center p-ai-center p-p-5"
        style={{ width: "100%", height: "100%" }}
      >
        <div
          style={{
            width: "100%",
            height: "100vh",
            backgroundColor: "white",
          }}
        >
          <div className="box-1">
            {" "}
            <p id="introducing-txt">
              Az első programomat még a gimnáziumban írtam Basic nyelven egy
              HT-1080-on. Ezt 3 év programozás követte <b>dBase és Clipper </b>
              nyelveken. Egy ERP rendszer fejlesztésében én is szerepet kaptam.
              Később hosszú ideig az informatikai értékesítésben dolgoztam, majd
              pár éve úgy döntöttem újra megpróbálkozom a szoftverfejlesztéssel.
              Ez a munka rendkívül kreatív, elmélyült gondolkodást,
              rendszerszemléletet kíván. Ma már az objektum orientált
              programozás persze nagyon más. 2021 áprilisában végeztem a
              CodeCool-nál, mint Front-End fejlesztő.{" "}
              <span className="technologiesZ">
                HTML-5, CSS/SASS, Javascript, React, Node.JS
              </span>{" "}
              kódokon tanultunk. Rendelkezem{" "}
              <span className="technologiesZ">GitHub, Docker</span>{" "}
              ismeretekkel.
              <p id="introducing-txt2">
                Nyitott vagyok új projektekre, megbízásokra, örömmel dolgozom
                csapatban. Szeretném elmélyíteni az eddigi tudásomat és új
                területekkel is megismerkedni.
              </p>
            </p>
          </div>
          <div className="box-2">
            <div className="p-d-flex p-flex-column p-jc-end p-ai-center">
              <p className="my-contacts" style={{ marginLeft: "20%" }}>
                Kapcsolat
              </p>
              <ul className="contact-list">
                <li>
                  <FaPhoneAlt id="phone-icon" />
                  <FaWhatsapp id="whatsapp-icon" /> +3670-676-4266
                </li>
                <li>
                  <FaEnvelope id="email-icon" /> bgzoltan@gmail.com
                </li>
              </ul>
            </div>
            <div
              className="p-d-flex p-jc-center p-ai-center"
              style={{ width: "100%", height: "100%" }}
            >
              <ul className="link-list">
                <li>
                  <a href="https://www.linkedin.com/in/zolt%C3%A1n-bagd%C3%A1ny-b40448160/">
                    <FaLinkedin style={{ fontSize: "2rem" }} />
                  </a>
                </li>
                <li>
                  <a href="#">
                    {" "}
                    <FaGithub id="github-icon" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="profile-img-zoli"></div>
        <div className="profile-container p-d-flex p-flex-column p-jc-center p-ai-center">
          <p className="welcome-message" style={{ textAlign: "center" }}>
            BAGDÁNY ZOLTÁN
          </p>
          <p id="welcome-message">
            Junior Front End fejlesztő és
            <p style={{ textAlign: "center" }}>Web Designer</p>
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default AboutUs;
