import React, { useRef, useEffect } from "react";

const Canvas = ({ counterSlice, isIncreased }) => {
  const canvasRef = useRef(null);
  // Centre of the circle on the Canvas
  let cx = 20;
  let cy = 20;
  // Radius of the circle
  let r = 19;

  const draw = ctx => {
    ctx.beginPath();
    // Starting point fo the draw

    // Calculating the slices
    if (isIncreased) {
      ctx.moveTo(cx, cy);
      ctx.strokeStyle = "#e69138";
      ctx.lineWidth = 2;
      ctx.arc(
        cx,
        cy,
        r,
        -Math.PI / 2,
        -Math.PI / 2 + counterSlice * 45 * Math.PI / 180
      );
      ctx.lineTo(cx, cy);
    } else {
      if (counterSlice === 0) {
        ctx.lineTo(cx, cy);
      }
      ctx.strokeStyle = "white";
      ctx.lineWidth = 2.5;
      ctx.arc(
        cx,
        cy,
        r,
        -Math.PI / 2 + counterSlice * 45 * Math.PI / 180,
        -Math.PI / 2 + (counterSlice + 1) * 45 * Math.PI / 180
      );
      if (counterSlice !== 7) {
        ctx.lineTo(cx, cy);
      }
    }
    // ctx.strokeStyle = "red";

    ctx.stroke();
    // isIncreased === true
    //   ? (ctx.fillStyle = "#1e1c1c")
    //   : (ctx.fillStyle = "white");
    // ctx.fill();
  };

  useEffect(
    () => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      //Our draw come here
      draw(ctx);
    },
    [draw]
  );

  return (
    <canvas
      width="40"
      height="40"
      className="pizzaSliceFigure"
      ref={canvasRef}
    />
  );
};

export default Canvas;
