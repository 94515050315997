import React from "react";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";

function Footer() {
  return (
    <div className="footer p-d-flex p-ai-center p-jc-between">
      <div
        className="p-d-flex  p-ai-center"
        style={{ width: "100%", height: "100%", fontFamily: "Roboto Moto" }}
      >
        <p className="footerDetails">&#169; 2021 Dér Ádám & Bagdány Zoltán</p>
      </div>
      <div
        className="p-d-flex p-flex-column p-jc-center p-ai-center"
        style={{ width: "100%", height: "5vh" }}
      >
        <p className="footerTitle">NYITVATARTÁS</p>
        <p className="footerDetails">H - P 9.30 - 22.00</p>
        <p className="footerDetails">SZ - V 12.00 - 22.00</p>
        <p className="footerDetails">Tel.: 345-67-34</p>
      </div>
      <div
        className="p-d-flex p-flex-column p-jc-center p-ai-center"
        style={{ width: "100%" }}
      >
        <p className="footerTitle">HÁZHOZSZÁLLÍTÁS</p>
        <p className="footerDetails">I. II. XII. ker.</p>
        <p className="footerDetails">H - P 12.00 - 22.00</p>
        <p className="footerDetails">SZ - V 12.00 - 20.00</p>
      </div>
      <div
        className="p-d-flex p-flex-column p-ai-center p-jc-between"
        style={{ width: "100%" }}
      >
        <div className="p-d-flex p-jc-around p-mt-4" style={{ width: "100%" }}>
          <FaFacebookF style={{ color: "#ff4545", fontSize: "1.5rem" }} />
          <FaInstagram style={{ color: "#ff4545", fontSize: "1.5rem" }} />
          <FaTwitter style={{ color: "#ff4545", fontSize: "1.5rem" }} />
        </div>
      </div>
    </div>
  );
}

export default Footer;
