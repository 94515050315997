import React from "react";
import { MdAdd, MdRemove } from "react-icons/md";
function Counter(props) {
  let handleIncrease = () => {
    console.log("props.CounterSlice", props.counterSlice, props.pizzaSum);

    props.setIsIncreased(true);
    props.setCounterSlice(
      props.counterSlice < 8 ? props.counterSlice + 1 : props.counterSlice
    );

    props.setSlicePrice(
      props.slicePrice === props.foodPrice
        ? props.slicePrice
        : props.slicePrice + props.foodPrice / 8
    );

    props.setPizzaSum(
      props.counterSlice < 8
        ? props.foodPrice / 8 + props.pizzaSum
        : props.pizzaSum
    );
  };

  let handleDecrease = () => {
    props.setIsIncreased(false);
    props.setCounterSlice(
      props.counterSlice > 0 ? props.counterSlice - 1 : props.counterSlice
    );
    props.setSlicePrice(
      props.slicePrice > 0
        ? props.slicePrice - props.foodPrice / 8
        : props.slicePrice
    );
    props.setPizzaSum(
      props.counterSlice > 0
        ? props.pizzaSum - props.foodPrice / 8
        : props.pizzaSum
    );
  };

  return (
    <div>
      <button id="btn-remove" className="decrease" onClick={handleDecrease}>
        <MdRemove />
      </button>
      <button id="btn-add" className="increase" onClick={handleIncrease}>
        <MdAdd />
      </button>
    </div>
  );
}

export default Counter;
