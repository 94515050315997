import React, { useState } from "react";

function Guestform({
  guestName,
  guestEmail,
  guestComment,
  guestStar1,
  guestStar2,
  guestStar3,
  guestStar4,
  guestStar5,
  setGuestName,
  setGuestEmail,
  setGuestComment,
  setGuestStar1,
  setGuestStar2,
  setGuestStar3,
  setGuestStar4,
  setGuestStar5,
  storeData
}) {
  const [validated, setValidated] = useState(false);
  const [buttonPressed, setButtonPressed] = useState(false);
  const [errorMessage1, setErrorMessage1] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");
  const [errorMessage3, setErrorMessage3] = useState("");
  const [errorMessage4, setErrorMessage4] = useState("");

  function checkData() {
    let newValidated = true;
    setValidated(newValidated);
    const emailRegex = /\S+@\S+\.\S+/;
    const nameRegex = /[A...Z]\w+/;
    const commentRegex = /\w+/;
    setButtonPressed(true);

    if (
      nameRegex.test(guestName) &&
      guestName.length > 3 &&
      guestName.split(" ").length == 1
    ) {
      setErrorMessage1("");
    } else {
      newValidated = false;
      setErrorMessage1("név,");
    }

    if (emailRegex.test(guestEmail)) {
      setErrorMessage2("");
    } else {
      newValidated = false;
      setErrorMessage2("email,");
    }

    if (
      commentRegex.test(guestComment) &&
      guestComment.length > 5 &&
      guestComment.split(" ").length >= 2
    ) {
      setErrorMessage3("");
    } else {
      newValidated = false;
      setErrorMessage3("komment,");
    }

    if (
      !guestStar1 &&
      !guestStar2 &&
      !guestStar3 &&
      !guestStar4 &&
      !guestStar5
    ) {
      newValidated = false;
      setErrorMessage4("értékelés");
    } else {
      setErrorMessage4("");
    }

    setValidated(newValidated);
    console.log(newValidated);

    if (newValidated) {
      storeData();
    }
  }

  return (
    <div id="formOnVideo">
      <div id="opinionFormOpen">
        <div className="p-d-flex">
          <input
            onChange={e => setGuestName(e.target.value)}
            onClick={e => setGuestName("")}
            id="guestName"
            name="guestName"
            value={guestName}
            size="15"
            placeholder="Felhasználónév"
          />
        </div>
        {/* e-mail ***************************************/}
        <div className="p-d-flex">
          {/* <label for="guestEmail" style={{ width: "90px" }}>
                      <i>e-mail</i>
                    </label> */}

          <input
            onChange={e => setGuestEmail(e.target.value)}
            onClick={e => setGuestEmail("")}
            id="guestEmail"
            name="guestEmail"
            value={guestEmail}
            placeholder="E-mail"
          />
        </div>
        {/* Guest  evaluation ***********************************************/}
        <div className="p-d-flex">
          {/* <label for="guestComment" style={{ width: "120px" }}>
                      <i>Vélemény</i>
                    </label> */}
          <textarea
            onChange={e => setGuestComment(e.target.value)}
            onClick={e => setGuestComment("")}
            id="guestComment"
            name="guestComment"
            value={guestComment}
            rows="5"
            cols="30"
            placeholder="Pl.: Véleményem szerint..."
          />
        </div>
        <div className="p-d-flex">
          <input
            onChange={e =>
              guestStar1 ? setGuestStar1(false) : setGuestStar1(true)}
            className="star"
            type="checkbox"
            name="star1"
            checked={guestStar1}
          />
          {/* Distance between stars*/}
          <span style={{ width: "25px" }} />
          <input
            onChange={e =>
              guestStar2 ? setGuestStar2(false) : setGuestStar2(true)}
            className="star"
            type="checkbox"
            name="star2"
            checked={guestStar2}
          />
          {/* Distance between stars*/}
          <span style={{ width: "25px" }} />
          <input
            onChange={e =>
              guestStar3 ? setGuestStar3(false) : setGuestStar3(true)}
            className="star"
            type="checkbox"
            name="star3"
            checked={guestStar3}
          />
          {/* Distance between stars*/}
          <span style={{ width: "25px" }} />
          <input
            onChange={e =>
              guestStar4 ? setGuestStar4(false) : setGuestStar4(true)}
            className="star"
            type="checkbox"
            name="star4"
            checked={guestStar4}
          />
          {/* Distance between stars*/}
          <span style={{ width: "25px" }} />
          <input
            onChange={e =>
              guestStar5 ? setGuestStar5(false) : setGuestStar5(true)}
            className="star"
            type="checkbox"
            name="star5"
            checked={guestStar5}
          />
        </div>
        {/* Submit button of the FORM *****************************/}
        <div className="p-d-flex p-jc-center" style={{ width: "100%" }}>
          <button
            className="buttonStyle"
            onClick={() => checkData()}
            id="guestSubmit"
          >
            ELKÜLDÖM
          </button>
        </div>{" "}
        {buttonPressed &&
          !validated &&
          <div>
            {`Nem megfelelő: ${errorMessage1} ${errorMessage2} ${errorMessage3} ${errorMessage4}
            !`}
          </div>}
        {buttonPressed &&
          validated &&
          <div>
            {`Köszönjük az értékelésed!`}
          </div>}
      </div>
    </div>
  );
}

export default Guestform;
