import React from "react";
import Navbar from "./NavBar";
import Footer from "./Footer";
import UnderConstruction from "./UnderConstruction";

function Booking() {
  return (
    <div id="componentContainer">
      <Navbar />
      <UnderConstruction />
      <Footer />
    </div>
  );
}

export default Booking;
