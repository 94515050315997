import React, { useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import foods from "../data/foods.json";
import chefOffer from "../data/chefOffer.json";
import reviews from "../data/reviews.json";
import Footer from "./Footer";
import NavBar from "./NavBar";

function LandingPage() {
  let [showDessert, setShowDessert] = useState(false);
  let [indxOfReviews, setIndxOfReviews] = useState(0);
  let [indxOfImg, setIndxOfImg] = useState(0);
  let [isClickedP, setIsClickedP] = useState(true);
  let [isClickedD, setIsClickedD] = useState(false);
  let [isClickedB, setIsClickedB] = useState(false);

  //BACKGROUND-IMG SLIDE-SHOW//
  let bcgImgs = [
    `url(img/Pizza1.jpeg)`,
    `url(img/Tomato-basil-sauce.jpg)`,
    `url(img/pizzaiolo5.jpg)`,
    `url(img/napolitanOven.jpeg)`,
    `url(img/napolitanPizza5.jpg)`,
    `url(img/vespa.jpg)`,
  ];

  console.log(indxOfImg, bcgImgs.length);

  //FILTER OUT THE PIZZAS IN ORDER TO RENDER THE DAILY MENU//
  let dailyOffer = foods.filter((food) => food.type === "pizza");

  //SORTING THE REVIEWS CRONOLOGIC ORDER//
  let freshReviews = reviews.sort((acc, curr) =>
    acc.date < curr.date ? 1 : -1
  );

  //GET THE PREMIUM PIZZAS FOR RENDERING//
  let premiumPizzas = foods.filter(
    (food) => food.type === "pizza" && food.style === "premium"
  );

  let day;
  switch (new Date().getDay()) {
    case 1:
      day = "Monday";
      break;
    case 2:
      day = "Thuesday";
      break;
    case 3:
      day = "Wednesday";
      break;
    case 4:
      day = "Thursday";
      break;
    case 5:
      day = "Friday";
      break;
    case 6:
      day = "Saturday";
      break;
    case 0:
      day = "Sunday";
      break;
  }
  let getPizza = () => {
    setIsClickedP(true);
    setIsClickedD(false);
    setIsClickedB(false);
  };
  let getDrink = () => {
    setIsClickedP(false);
    setIsClickedD(false);
    setIsClickedB(true);
  };
  let getDessert = () => {
    setIsClickedP(false);
    setIsClickedD(true);
    setIsClickedB(false);
  };

  //Rendering the reviews
  let previousReview = () => {
    setIndxOfReviews(indxOfReviews > 0 ? indxOfReviews - 1 : indxOfReviews);
  };
  let nextReview = () => {
    setIndxOfReviews(
      indxOfReviews !== reviews.length - 1 ? indxOfReviews + 1 : indxOfReviews
    );
  };

  //RENDERING THE IMAGES FOR THE SLIDER//
  let nextImg = () => {
    setIndxOfImg(
      indxOfImg !== bcgImgs.length - 1 ? indxOfImg + 1 : (indxOfImg = 0)
    );
  };
  let previousImg = () => {
    setIndxOfImg(
      indxOfImg > 0 ? indxOfImg - 1 : (indxOfImg = bcgImgs.length - 1)
    );
  };

  //AUTO-SLIDE-SHOW//
  /*useEffect(() => {
    window.setInterval(() => {
      if (indxOfImg !== bcgImgs.length - 1) {
        indxOfImg++;
      } else {
        indxOfImg = 0;
      }
      setIndxOfImg(indxOfImg);
    }, 3000);
  }, []);*/

  return (
    <div>
      <NavBar />
      <div className="header-img p-jc-center p-ai-center">
        <div
          className="logo-header p-d-flex p-flex-column p-ai-center"
          style={{ position: "absolute", marginTop: "7%" }}
        >
          <img
            src="/img/colosseum-logo-white.png"
            alt="colosseum"
            height="200px"
            width="200px"
            style={{ marginTop: "15%" }}
          />
          <h1
            style={{
              position: "relative",
              bottom: "20%",
              fontSize: "5rem",
            }}
          >
            Colosseo
          </h1>
        </div>
        {/* SLIDES ************************************************** */}
        <div
          className="slides p-d-flex p-ai-center p-jc-between"
          style={{ backgroundImage: `${bcgImgs[indxOfImg]}` }}
        >
          <FaAngleLeft
            className="imgSlider-button"
            style={{ fontSize: "7rem" }}
            onClick={previousImg}
          />
          <FaAngleRight
            className="imgSlider-button"
            style={{ fontSize: "7rem" }}
            onClick={nextImg}
          />
        </div>
      </div>

      {/* BENVENUTI ************************************************* */}
      <section id="benvenuti" className="p-d-flex p-flex-row-reverse">
        <div
          id="benvenutiRightSide"
          className="p-d-flex p-jc-center p-ai-center"
        >
          <h4 id="benvenutiTitle">~ Benvenuti ~</h4>
        </div>
        <div
          className="p-d-flex p-jc-center p-flex-column p-ai-center"
          id="benvenutiText"
        >
          <h5> Üdvözlünk a honlapunkon! </h5>
          <p>
            Szoftverfejlesztők vagyunk és imádjuk a pizzát, ezért úgy
            döntöttünk, hogy az egyik bemutatkozó oldalunk egy elképzelt
            pizzéria oldala lesz. Az oldal felépítését és funkcionalitását is
            ennek megfelelően alakítottuk ki. Így tájékozódhatsz a
            kínálatunkról, küldhetsz rendeléseket és beleírhatsz a
            vendégkönyvünkbe is.{" "}
            <i>
              Az oldal mögött jelenleg nincs szerver így a rendelések leadása és
              a vendégkönybe írás is csak addig marad meg, amíg az oldalon
              tartózkodsz.{" "}
            </i>
            Reméljük tetszeni fog az oldalunk! Ha kérdésed lenne vagy netán
            szeretnél megbízni minket egy hasonló oldal fejlesztésével, keress
            minket a "RÓLUNK" menüpont alatt.
          </p>
        </div>
      </section>

      {/* PIZZA OVEN image******************************************** */}
      <section
        className="pizza-img1"
        style={{ width: "100%", height: "70vh" }}
      />

      {/* MENU ******************************************************* */}
      <section id="foodMenu">
        {/* MENU TITLE */}
        <div
          className="p-d-flex p-flex-column p-ai-center p-jc-center"
          id="foodMenuTitle"
        >
          <h4>~ Menu ~</h4>
          <div id="foodMenuBox" className="p-d-flex p-flex-column">
            <ul id="foodMenuList" className="p-d-flex p-jc-around p-ai-center">
              <li
                onClick={getPizza}
                id="pizzaMenu"
                style={{
                  textDecoration: isClickedP ? "underline" : "none",
                }}
              >
                Pizzák
              </li>

              <li
                onClick={getDessert}
                id="dessertMenu"
                style={{
                  textDecoration: isClickedD ? "underline" : "none",
                }}
              >
                Desszertek
              </li>
              <li
                onClick={getDrink}
                id="drinkMenu"
                style={{
                  textDecoration: isClickedB ? "underline" : "none",
                }}
              >
                Italok
              </li>
            </ul>
          </div>
        </div>

        {/* MENU items */}
        <div className="vespa-img" style={{ width: "100%", height: "100vh" }}>
          <div className="menu p-d-flex p-jc-center p-ai-center p-p-3">
            {showDessert && (
              <div>
                {foods.map(
                  (food, indexD) =>
                    food.type === "dessert" && (
                      <div
                        style={{ width: "100%", color: "white" }}
                        className="p-d-flex p-jc-between p-ai-center p-p-4"
                        key={indexD}
                      >
                        <p
                          style={{
                            fontSize: "1rem",
                            fontWeight: "900",
                            letterSpacing: "0.1rem",
                          }}
                        >
                          {food.name}
                          <br />
                          {food.ingredients && (
                            <span className="menuFoodDetails">
                              {food.ingredients.join(", ")}
                            </span>
                          )}
                        </p>
                        <p className="menuFoodPrice">{food.price}</p>
                      </div>
                    )
                )}
              </div>
            )}

            <div
              style={{ width: "100%", height: "100%" }}
              className="p-d-flex p-jc-center p-ai-center"
            >
              <div className=" menuFood p-d-flex p-flex-column p-jc-center p-ai-center p-p-2">
                <p className="menuFoodTitle">KLASSZIKUS pizzák</p>
                {foods.map(
                  (food, indxP) =>
                    food.type === "pizza" &&
                    food.style === "classic" && (
                      <div
                        key={indxP}
                        style={{ width: "100%" }}
                        className="p-d-flex p-jc-between "
                      >
                        <p className="menuFoodName">
                          {food.name}
                          <br />
                          <span className="menuFoodDetails">
                            {food.ingredients.join(", ")}
                          </span>
                        </p>
                        <p className="menuFoodPrice">{food.price},-Ft</p>
                      </div>
                    )
                )}

                <p className="menuFoodTitle">PRÉMIUM pizzák</p>
                {premiumPizzas.map(
                  (premium, indxP) =>
                    indxP >= 0 &&
                    indxP <= 3 && (
                      <div
                        key={indxP}
                        style={{ width: "100%" }}
                        className="p-d-flex p-jc-between "
                      >
                        <p className="menuFoodName">
                          {premium.name}
                          <br />
                          <span className="menuFoodDetails">
                            {premium.ingredients.join(", ")}
                          </span>
                        </p>
                        <p className="menuFoodPrice">{premium.price},-Ft</p>
                      </div>
                    )
                )}
              </div>
            </div>
            <div className="p-d-flex p-flex-column p-jc-center p-ai-center menuFood">
              {premiumPizzas.map(
                (premium, indxP) =>
                  indxP > 3 &&
                  indxP <= premiumPizzas.length && (
                    <div
                      key={indxP}
                      style={{ width: "100%" }}
                      className="p-d-flex p-jc-between"
                    >
                      <p className="menuFoodName">
                        {premium.name}
                        <br />
                        <span className="menuFoodDetails">
                          {premium.ingredients.join(", ")}
                        </span>
                      </p>
                      <p className="menuFoodPrice">{premium.price},-Ft</p>
                    </div>
                  )
              )}
              <div
                style={{
                  width: "100%",
                  height: "20vh",
                }}
              ></div>
            </div>
          </div>
        </div>
      </section>

      {/*GUEST BOOK ******************************************************  */}
      <section id="guestBook" className="p-d-flex">
        <div
          className="p-d-flex p-jc-center p-ai-center"
          style={{ width: "100%", height: "60vh", backgroundColor: "#1e1c1c" }}
        >
          <h4>~ Vendégkönyv ~</h4>
        </div>
        <div
          id="guestBookRightSide"
          className="p-d-flex p-jc-center p-ai-center"
        >
          <div>
            <button id="review-btn" onClick={previousReview}>
              <FaAngleLeft style={{ fontSize: "3.5rem", color: "#1e1c1c" }} />
            </button>
          </div>
          <div id="review-container">
            {freshReviews.map(
              (review, indexRev) =>
                indexRev === indxOfReviews && (
                  <div key={indexRev}>
                    <em>
                      <bold> {review.username}</bold>

                      <small> {" nevű vendégünk írta"}</small>
                    </em>
                    <br />
                    <br />"{review.comment}"
                    <p style={{ fontSize: "1.2rem", marginTop: "1rem" }}>
                      <br /> {review.date}
                    </p>
                    <p
                      style={{
                        fontSize: "2rem",
                        color: "#ff4545",
                        letterSpacing: "0.3rem",
                      }}
                    >
                      {review.rate}
                    </p>
                  </div>
                )
            )}
          </div>
          <div>
            <button id="review-btn" onClick={nextReview}>
              <FaAngleRight style={{ fontSize: "3.5rem", color: "#1e1c1c" }} />
            </button>
          </div>
        </div>
      </section>

      {/* OFFERS ***************************************************** */}
      <section id="offers" className="offers-container p-d-flex">
        <div
          className="p-d-flex p-flex-column p-jc-center p-ai-center"
          style={{ width: "40%", height: "100%", backgroundColor: "white" }}
        >
          {chefOffer.map((offer, indexP) => (
            <div
              className="offerBox p-d-flex p-flex-column p-jc-start p-ai-center"
              key={indexP}
            >
              <p className="offerTitle">A CHEF AJÁNLATA</p>
              <p className="offerName">{offer.name}</p>
              <p className="offerDetails">{offer.ingredients.join(", ")}</p>
              <p className="offerPrice">{offer.price},-Ft</p>
            </div>
          ))}
        </div>
        <div
          className="offer-img p-d-flex p-flex-column p-jc-center p-ai-center"
          style={{ width: "30%", height: "100%" }}
        >
          <div
            className="p-d-flex p-jc-center p-ai-center"
            style={{
              width: "100%",
              height: "100vh",
              backgroundColor: "#ff4545",
              opacity: "0.7",
            }}
          >
            <div className="offerBox p-d-flex p-flex-column p-jc-center p-ai-center">
              <p id="special-offer">APERITIVO</p>
              <p className="offerName aperitivo">1x szelet pizza</p>
              <p className="offerName aperitivo" style={{ margin: "0" }}>
                +
              </p>
              <p className="offerName aperitivo">APEROL spritz / 0.5l sör</p>
              <p
                className="offerPrice"
                style={{
                  color: "white",
                }}
              >
                2000,-Ft
              </p>

              <p
                className="offerDetails"
                style={{
                  color: "white",
                }}
              >
                <b>MINDEN NAP 16.00 - 20.00 - IG</b>
              </p>
            </div>
          </div>
        </div>
        <div
          className="p-d-flex p-flex-column p-jc-center p-ai-center"
          style={{ width: "40%", height: "100%", backgroundColor: "#1e1c1c" }}
        >
          {dailyOffer.map(
            (offer, indexOff) =>
              day === offer.day && (
                <div
                  className=" p-d-flex p-flex-column p-jc-center p-ai-center"
                  key={indexOff}
                >
                  <p className="offerTitle">NAPI AJÁNLATUNK</p>
                  <p className="offerName" style={{ color: "white" }}>
                    {offer.name}
                  </p>
                  <p className="offerDetails " style={{ color: "white" }}>
                    {offer.ingredients.join(", ")}
                  </p>
                  <p className="offerPrice">{offer.price},-Ft</p>
                </div>
              )
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default LandingPage;
