import "./out/App.css";
// import logo from "/img/colosseum-logo-white.png";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React from "react";
// import NavBar from "./Components/NavBar";
import LandingPage from "./Components/LandingPage";
import AboutUs from "./Components/AboutUs";
import Booking from "./Components/Booking";
import Order from "./Components/Order";
import FinalStep from "./Components/Finalstep";
import Guestbook from "./Components/Guestbook";
import Register from "./Components/Register";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/" exact component={LandingPage} />
          <Route path="/menu-order" component={Order} />
          <Route path="/aboutus" component={AboutUs} />
          <Route path="/booking" component={Booking} />
          <Route path="/register" component={Register} />
          <Route path="/delivery" component={FinalStep} />
          <Route path="/guestbook" component={Guestbook} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
