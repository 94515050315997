export function guestList(newUsername, newComment, newDate, newEmail, newRate) {
  let guestOpinion = [];
  class Opinion {
    constructor(username, comment, date, email, rate) {
      this.username = username;
      this.comment = comment;
      this.date = date;
      this.email = email;
      this.rate = rate;
    }
    get stars() {
      return this.starString();
    }
    starString() {
      let starString = "";
      for (let i = 1; i <= this.rate; i++) {
        starString = starString + "*";
      }
      return starString;
    }
  }

  // First time building the guestOpinion list
  if (newUsername === undefined) {
    guestOpinion = [
      new Opinion(
        "Zoli",
        "Eredetii olasz pizza többféle stílusban, igazi olasz hangulattal fűszerezve",
        "2021-03-08",
        "zoli@gmail.com",
        5
      ),
      new Opinion(
        "András",
        "A legjobb választás egy kis munka utáni lazításhoz .",
        "2021-03-17",
        "andras@gmail.com",
        5
      ),
      new Opinion(
        "Bálint",
        "Minőségi alapanygok és fergeteges hangulat övezi. Egyszerűen nagyszerű. ",
        "2021-03-24",
        "balint@gmail.com",
        5
      ),
      new Opinion(
        "Éva",
        "Az esti buli előtt alapoznál de azért ennél is valami gyorsat és minőségit? A válasz Colosseo!",
        "2021-04-01",
        "eva@gmail.com",
        5
      ),
      new Opinion(
        "Tünde",
        "Kiváló hely, a kiszolgálás 5*, a nutellás sajttortát mindneképpen próbáljátok ki!",
        "2021-04-04",
        "tunde@gmail.com",
        5
      ),
      new Opinion(
        "Zsolt",
        "Csak bekapnál valamit vagy maradnál is?! Itt a helyed!",
        "2021-04-10",
        "zsolt@gmail.com",
        4
      ),
      new Opinion(
        "Apollónia",
        "Az elmúlt hónapokban törzshelyünkké vált, minden héten jövünk a csajokkal Aperol Spritzezni",
        "2021-04-10",
        "apollonia@gmail.com",
        4
      ),
      new Opinion("Dalma", "Tiramisu for president! :)", "2021-04-15", "", 5),
      new Opinion(
        "Hacker",
        "Olasz? Hm....a Don-Pepe az igazi...",
        "2021-04-20",
        "hacker@gmail.com",
        1
      ),
      new Opinion(
        "Attila",
        "Véletlenül jöttem be ide a vihar elől de ha már így alakult szívesen maradtam pár órát.",
        "2021-05-02",
        "attila@gmail.com",
        5
      ),
      new Opinion(
        "Man from Milano",
        "Molto bene, molto bene. Grazie.",
        "2021-05-05",
        "milanoman@gmail.com",
        5
      ),
      new Opinion(
        "Zsolt",
        "Nem gondoltam volna, hogy találok egy ehhez fogható helyet Budán pedig csak üzleti ebédre ugrottam ki a kollegákkal .",
        "2021-05-12",
        "zsolti@gmail.com",
        4
      ),
      new Opinion(
        "Richard",
        "Örülök, hogy a Colosseo alkotója egyszer szintén CodeCool-os diák volt ;) .",
        "2021-05-16",
        "richard@gmail.com",
        5
      ),
      new Opinion(
        "Gabi",
        "Kiváló kiszolgálás. Fantasztikus ízek. Máskor is jönni fogok. Ha több csillag is lenne...",
        "2021-05-24",
        "gabi@gmail.com",
        5
      ),
      new Opinion(
        "Ferenc",
        "Itt ettünk az egész családdal. Igazi olaszos hangulat... Így tovább!",
        "2021-05-26",
        "ferenc@gmail.com",
        5
      )
    ];
  } else {
    guestOpinion.push(
      new Opinion(newUsername, newComment, newDate, newEmail, newRate)
    );
  }

  // guestOpinion.forEach(element => console.log(element.username, element.stars));

  return guestOpinion;
}
