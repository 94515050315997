import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { MdShoppingCart, MdDirectionsCar } from "react-icons/md";
//import Cart from "./Cart";
function NavBar() {
  let [showNav, setShowNav] = useState(true);

  let schedule;
  switch (new Date().getDay()) {
    case 1:
      schedule = "9.30 - 22.00";
      break;
    case 2:
      schedule = "9.30 - 22.00";
      break;
    case 3:
      schedule = "9.30 - 22.00";
      break;
    case 4:
      schedule = "9.30 - 22.00";
      break;
    case 5:
      schedule = "9.30 - 22.00";
      break;
    case 6:
      schedule = "12.00 - 22.00";
      break;
    case 0:
      schedule = "12.00 - 22.00";
      break;
  }

  window.onscroll = function () {
    if (window.scrollY > 120) {
      setShowNav(false);
    } else {
      setShowNav(true);
    }
  };
  return (
    <div>
      {!showNav ? (
        <div className="flag">
          <div className="burger-menu p-d-flex p-ai-center">
            <div className="logo p-d-flex p-ai-center">
              <Link to="/">
                <img src="/img/colosseum-icon-6.jpg" alt="colosseum" />
              </Link>
              <p id="logo-txt">Colosseo</p>
            </div>

            <div className="p-d-flex p-ai-center" style={{ color: "black" }}>
              <p id="homeDelivery" className="p-ml-3">
                TELEFON: <b>1-345-6734</b>
              </p>
              <p className="space" />
              <MdDirectionsCar />
              <p id="homeDelivery" className="p-ml-3">
                ÁTLAGOS HÁZHOZSZÁLLÍTÁSI IDŐ: <b>30-40 perc</b>
              </p>
            </div>

            <div>
              <p id="openToday">
                MA NYITVA: <b>{schedule}</b>
              </p>
            </div>

            <div className="p-d-flex p-ai-center">
              <Link to="/menu-order" style={{ textDecoration: "none" }}>
                <div className="buttonContainer">
                  <div className="buttonStyle">RENDELJ MOST</div>
                  {/* <div className="buttonStyleHover">RENDELJ MOST</div> */}
                </div>
              </Link>

              <div className="p-d-flex p-ai-center p-mr-4">
                <MdShoppingCart
                  style={{ fontSize: "1.4rem", color: "#1e1c1c" }}
                />
                <p className="cart">{"0 db"}</p>
              </div>

              <div id="burger" onClick={() => setShowNav(true)}>
                <div className="burger-layer top" />
                <div className="burger-layer middle" />
                <div className="burger-layer bottom" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flag">
          <nav
            className="nav p-d-flex p-ai-center p-jc-between"
            style={{ borderBottom: "4px solid rgb(98, 232, 98)" }}
          >
            <div className="logo p-d-flex p-ai-center" style={{ width: "20%" }}>
              <Link to="/">
                <img
                  src="/img/colosseum-logo-white.png"
                  alt="colosseum"
                  height="80px"
                />
              </Link>
              <p id="logo-txt">Colosseo</p>
            </div>
            <div
              className="nav-buttons p-d-flex p-flex-column p-ai-center p-jc-center"
              style={{ width: "60%" }}
            >
              {/* <p>1026 BUDAPEST SZÉLL KÁLMÁN TÉR 5. TEL.: 345-67-34</p> */}

              <div className="nav-links p-d-flex p-jc-around">
                <p>
                  <NavLink
                    to="/aboutus"
                    className="nav-button"
                    activeClassName="is-active"
                  >
                    RÓLUNK
                  </NavLink>
                </p>
                <p>
                  <NavLink
                    to="/booking"
                    className="nav-button"
                    activeClassName="is-active"
                  >
                    FOGLALÁS
                  </NavLink>
                </p>
                <p>
                  <NavLink
                    to="/guestbook"
                    className="nav-button"
                    activeClassName="is-active"
                  >
                    VENDÉGKÖNYV
                  </NavLink>
                </p>
                <p>
                  <NavLink
                    to="register"
                    className="nav-button"
                    activeClassName="is-active"
                  >
                    REGISZTRÁCIÓ
                  </NavLink>
                </p>
              </div>
            </div>

            <div
              className="p-d-flex p-ai-center p-jc-end p-mr-5"
              style={{ width: "20%" }}
            >
              <Link to="/menu-order" style={{ textDecoration: "none" }}>
                <div className="buttonContainer">
                  <div className="buttonStyle">RENDELJ MOST</div>
                </div>
              </Link>
              <MdShoppingCart style={{ fontSize: "1.4rem" }} />
              <p className="cart">{"0 db"}</p>
            </div>
          </nav>
        </div>
      )}
    </div>
  );
}

export default NavBar;
