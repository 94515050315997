import React from "react";
import { MdAdd, MdRemove } from "react-icons/md";
function CounterWhole(props) {
  let RemoveQuantity = () => {
    props.setCounterWhole(
      props.counterWhole > 0 ? props.counterWhole - 1 : props.counterWhole
    );
    props.setWholePrice(
      props.wholePrice > 0
        ? props.wholePrice - props.foodPrice
        : props.wholePrice
    );
    props.setPizzaSum(
      props.pizzaSum > 0 ? props.pizzaSum - props.foodPrice : props.pizzaSum
    );
  };

  let AddQuantity = () => {
    props.setCounterWhole(props.counterWhole + 1);
    props.setWholePrice(props.foodPrice * props.counterWhole);

    props.setPizzaSum(props.pizzaSum + props.foodPrice);
  };

  return (
    <div className="counter-btn p-d-flex">
      <button id="btn-remove" onClick={RemoveQuantity}>
        <MdRemove />
      </button>
      <button id="btn-add" onClick={AddQuantity}>
        <MdAdd />
      </button>
    </div>
  );
}

export default CounterWhole;
