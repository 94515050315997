import React from "react";

function UnderConstruction() {
  return (
    <div id="underComponentContainer">
      <div id="navBarMask" />
      <div id="underConstructionContainer">
        <h1>Ez az oldal fejlesztés alatt áll. Köszönjük a megértésedet.</h1>
      </div>
    </div>
  );
}

export default UnderConstruction;
