import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import Foods from "../data/foods.json";
import Navbar from "./NavBar";
import Footer from "./Footer";
import { guestList } from "./GuestList.js";
import Guestform from "./Guestform";

function Guestbook(props) {
  const [reviewsData, setReviewsData] = useState([]);
  const [guestName, setGuestName] = useState("");
  const [guestEmail, setGuestEmail] = useState("");
  const [guestComment, setGuestComment] = useState("");
  const [guestStar1, setGuestStar1] = useState(false);
  const [guestStar2, setGuestStar2] = useState(false);
  const [guestStar3, setGuestStar3] = useState(false);
  const [guestStar4, setGuestStar4] = useState(false);
  const [guestStar5, setGuestStar5] = useState(false);
  const [guestBookFilter, setGuestBookFilter] = useState("");
  const [isOpinionButtonOn, setIsOpinionButtonOn] = useState(false);
  // const [selectedPizza, setSelectedPizza] = useState(Foods[0].name);

  useEffect(() => {
    let reviewList = guestList();
    setReviewsData(reviewList);
  }, []);

  //   Later on guest data will be saved on server side ..............
  function storeData(event) {
    let rate = "";
    if (guestStar1) {
      rate = 1;
    }
    if (guestStar2) {
      rate = 2;
    }
    if (guestStar3) {
      rate = 3;
    }
    if (guestStar4) {
      rate = 4;
    }
    if (guestStar5) {
      rate = 5;
    }

    let currentDate = new Date();
    let currentDateStr =
      currentDate.getFullYear() +
      "-" +
      currentDate.getMonth() +
      "-" +
      currentDate.getDate();

    console.log(guestName, guestComment, currentDateStr, rate);
    let reviewList = guestList(
      guestName,
      guestComment,
      currentDateStr,
      "",
      rate
    );
    console.log(reviewList);
    let newReviewsData = [...reviewsData, reviewList[0]];
    setReviewsData(newReviewsData);
    setIsOpinionButtonOn(false);
  }

  return (
    <div id="borderGuest">
      <Navbar />
      <div
        className="video-container"
        style={{
          position: "relative",
          width: "100%",
          height: "80vh"
        }}
      >
        <video autoPlay muted loop id="videoBcg">
          <source src="/img/Piizzatomato.mp4" type="video/mp4" />
        </video>
        <div className="video-overlay" />
      </div>
      <Guestform
        guestName={guestName}
        guestEmail={guestEmail}
        guestComment={guestComment}
        guestStar1={guestStar1}
        guestStar2={guestStar2}
        guestStar3={guestStar3}
        guestStar4={guestStar4}
        guestStar5={guestStar5}
        setGuestName={setGuestName}
        setGuestEmail={setGuestEmail}
        setGuestComment={setGuestComment}
        setGuestStar1={setGuestStar1}
        setGuestStar2={setGuestStar2}
        setGuestStar3={setGuestStar3}
        setGuestStar4={setGuestStar4}
        setGuestStar5={setGuestStar5}
        storeData={storeData}
      />
      <div id="filteringBar" className="p-d-flex p-jc-cenrter p-ai-center">
        <FaSearch style={{ fontSize: "1.5rem" }} />
        <label style={{ fontSize: "1.5rem", margin: "0 1rem 0 1rem" }}>
          Keresés
        </label>
        <input
          onChange={e => setGuestBookFilter(e.target.value)}
          id="guestBookFilter"
          value={guestBookFilter}
          placeholder="pl. 2021-03-06"
        />
      </div>
      <div id="guestReviews" style={{ backgroundColor: "#1e1c1c" }}>
        {reviewsData.map((obj, indexRev) =>
          <div key={indexRev}>
            {obj.date.includes(guestBookFilter)
              ? <div>
                  <div className="reviews">
                    <div className="card">
                      <div id="reviewComment">
                        {`${obj.username} írta rólunk`}
                        <br />
                        <br />
                        {obj.comment}
                        <i>
                          <small>
                            <br /> {obj.date}
                          </small>
                        </i>
                        <div id="foodRate">
                          {obj.stars}
                        </div>
                      </div>
                      <div />
                    </div>
                  </div>
                </div>
              : <div />}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Guestbook;
