import React, { useState, useEffect } from "react";
import Pizza from "./Pizza";
import { FaTrash } from "react-icons/fa";
import foods from "../data/foods.json";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import NavBar from "./NavBar";

function Order() {
  let [foodList, setFoodList] = useState(foods);
  let [deliveryPrice, setDeliveryPrice] = useState(390);
  let [orderedList, setOrderedList] = useState([]);
  let [sumOfOrderList, setSumOfOrderList] = useState(0);

  //COUNT THE TOTAL PRICE OF THE ORDERLIST//

  useEffect(() => {
    console.log("Changed", orderedList);
    let newSumOfOrderList = orderedList.reduce(
      (acc, curr) => acc + curr.sumPrice,
      0
    );
    setSumOfOrderList(newSumOfOrderList);
  }, [orderedList]);
  console.log(sumOfOrderList);

  let sumOfSlicePriceList = orderedList.reduce(
    (acc, curr) => acc + curr.slicePrice,
    0
  );

  //LOGIC FOR RENDERING PRICES...CHECK IF THE ORDEREDLIST HAS WHOLE-PIZZA//
  let isWholePizza = false;
  orderedList.map((order) =>
    order.counterWhole !== 0 ? (isWholePizza = true) : isWholePizza
  );

  //ORDERLIST COUNTER FOR THE CUSTOMERS CAN MODIFY QUNTITY OF WHOLEPIZZA OR DELETE THEIR CART//
  let addWhole = (Indx) => {
    let newOrderedList = [...orderedList];
    newOrderedList[Indx].counterWhole += 1;
    newOrderedList[Indx].sumPrice += newOrderedList[Indx].price;
    setOrderedList(newOrderedList);
  };
  let removeWhole = (Indx) => {
    let newOrderedList = [...orderedList];
    newOrderedList[Indx].counterWhole -= 1;
    newOrderedList[Indx].sumPrice -= newOrderedList[Indx].price;
    if (newOrderedList[Indx].counterWhole < 1) {
      newOrderedList.splice(Indx, 1);
    }
    setOrderedList(newOrderedList);
  };
  //ORDERLIST COUNTER FOR THE CUSTOMERS CAN MODIFY THE QUANTITY OF THE SLICE OF PIZZA OR DELETE CART//
  let addSlice = (Indx) => {
    let newOrderedList = [...orderedList];
    newOrderedList[Indx].counterSlice += 1;
    newOrderedList[Indx].slicePrice += newOrderedList[Indx].slicePrice;
    newOrderedList[Indx].sumPrice =
      newOrderedList[Indx].counterWhole * newOrderedList[Indx].price +
      (newOrderedList[Indx].price / 8) * newOrderedList[Indx].counterSlice;
    setOrderedList(newOrderedList);
  };
  let removeSlice = (Indx) => {
    let newOrderedList = [...orderedList];
    newOrderedList[Indx].counterSlice -= 1;
    newOrderedList[Indx].slicePrice -= newOrderedList[Indx].price / 8;
    newOrderedList[Indx].sumPrice =
      newOrderedList[Indx].counterWhole * newOrderedList[Indx].price +
      (newOrderedList[Indx].price / 8) * newOrderedList[Indx].counterSlice;
    if (
      newOrderedList[Indx].counterSlice < 1 &&
      newOrderedList[Indx].counterWhole === 0
    ) {
      newOrderedList.splice(Indx, 1);
    }
    setOrderedList(newOrderedList);
  };

  return (
    <div id="parent-container">
      <NavBar />
      <div
        className="p-d-flex p-flex-row-reverse p-jc-between"
        style={{ width: "100%" }}
      >
        <div className="order-container">
          <div
            className="order-headerContainer p-mb-2"
            style={{ height: "40vh" }}
          />
          <div className="p-shadow-4 p-d-flex p-jc-between p-m-3 ">
            <p className="food-types">AKCIÓS PIZZÁK (32cm)</p>
            <p className="food-types">RÓMAI TÍPUSÚ PIZZÁK (32cm)</p>
            <p className="food-types">SZICÍLIAI TÍPUSÚ PIZZÁK</p>
            <p className="food-types">DESSZERTEK</p>
            <p className="food-types">ITALOK</p>
          </div>
          <div
            id="discountedPizza"
            className="p-shadow-3 p-d-flex p-jc-center p-m-3"
          >
            <h2>Akciós Pizzák</h2>
          </div>
          {foodList.map((food, indexF, list) => (
            <Pizza
              food={food}
              key={indexF}
              indexF={indexF}
              list={list}
              orderedList={orderedList}
              setOrderedList={setOrderedList}
            />
          ))}
        </div>
        <div className="sideBar p-shadow-3 p-d-flex p-flex-column p-ai-center  p-p-2">
          <div className="side-container p-d-flex p-flex-column p-ai-center p-mb-5">
            {orderedList.length === 0 && <p id="empty-cart1">A kosár üres!</p>}
            {orderedList.length === 0 && (
              <p id="empty-cart2">
                Nem adtál még semmit kosaradhoz! Kezdj el
                <br /> hozzáadni termékeket, ha szeretnél rendelni!
              </p>
            )}
            {orderedList.map((order, index) => (
              <div className="container" key={index}>
                <div
                  className="p-d-flex p-jc-between p-ai-start"
                  style={{
                    width: "100%",
                    height: "15%",
                  }}
                >
                  <p>
                    <b>Pizza {order.name}</b>
                  </p>
                  {order.counterWhole !== 0 ? (
                    <p className="sum-price">{order.sumPrice} Ft</p>
                  ) : (
                    <p className="sum-price">{order.slicePrice} Ft</p>
                  )}
                </div>

                <div>
                  {order.counterWhole !== 0 &&
                    order.extras.map((extra, indexExt) => (
                      <p className="order-extra" key={indexExt}>
                        + {extra.name}
                      </p>
                    ))}
                  {order.sides.map((side, indexSide) => (
                    <p className="order-extra" key={indexSide}>
                      + {side.name}
                    </p>
                  ))}
                  {order.description && (
                    <p className="order-extra">* {order.description}</p>
                  )}
                  {order.counterWhole !== 0 && (
                    <div
                      className="p-d-flex p-jc-end p-ai-start p-mt-4"
                      style={{
                        width: "100%",
                      }}
                    >
                      <button
                        className="orderList-counter"
                        onClick={() => removeWhole(index)}
                      >
                        {order.counterWhole === 1 ? (
                          <FaTrash style={{ color: "rgb(238, 70, 70)" }} />
                        ) : (
                          "-"
                        )}
                      </button>
                      <button id="orderListCount">{order.counterWhole}</button>
                      <button
                        className="orderList-counter"
                        onClick={() => addWhole(index)}
                      >
                        +
                      </button>
                    </div>
                  )}
                </div>
                {order.counterSlice !== 0 && (
                  <div className="container p-d-flex p-jc-between">
                    <div>
                      <p>
                        Szelet {order.name}
                        <span style={{ color: "grey" }}> (extra nélkül)</span>
                      </p>
                    </div>
                    <div className=" container p-d-flex p-jc-end p-ai-start p-mt-4">
                      <button
                        className="orderList-counter"
                        onClick={() => removeSlice(index)}
                      >
                        {order.counterSlice === 1 ? (
                          <FaTrash style={{ color: "rgb(238, 70, 70)" }} />
                        ) : (
                          "-"
                        )}
                      </button>
                      <button id="orderListCount">{order.counterSlice}</button>
                      <button
                        className="orderList-counter"
                        onClick={() => addSlice(index)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ))}
            <div className=" container p-d-flex p-jc-between">
              <p>Bruttó összesen</p>

              {orderedList.length !== 0 ? (
                isWholePizza === true ? (
                  <p>{sumOfOrderList} Ft</p>
                ) : (
                  <p>{sumOfSlicePriceList} Ft</p>
                )
              ) : (
                <p>0 Ft</p>
              )}
            </div>
            {orderedList.length !== 0 && (
              <div className="container p-d-flex p-jc-between">
                <p className="delivery-price">Kiszállítási díj </p>
                <p className="delivery-price">{deliveryPrice} Ft</p>
              </div>
            )}
            <div className=" container p-d-flex p-jc-between">
              <p>
                <b>Bruttó mindösszesen</b>
              </p>
              {orderedList.length === 0 ? (
                <p>
                  <b>{sumOfOrderList} Ft</b>
                </p>
              ) : isWholePizza === true ? (
                <p>{sumOfOrderList + deliveryPrice} Ft</p>
              ) : (
                <p>{sumOfSlicePriceList + deliveryPrice} Ft</p>
              )}
            </div>
          </div>
          <Link
            style={{ textDecoration: "none" }}
            to={
              orderedList.length > 0
                ? {
                    pathname: "/delivery",
                    state: { orderedList, sumOfOrderList },
                  }
                : "#"
            }
          >
            <div className="buttonContainer">
              <div className="buttonStyle">TOVÁBB</div>
            </div>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Order;
