import React, { useState } from "react";
import Footer from "./Footer";
import NavBar from "./NavBar";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { FaCcVisa, FaCcMastercard, FaCcAmex } from "react-icons/fa";

function Finalstep(props) {
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [pcodeOfDelivery, setPcodeOfDelivery] = useState("");
  const [townOfDelivery, setTownOfDelivery] = useState("");
  const [streetOfDelivery, setStreetOfDelivery] = useState("");
  const [commentsOfDelivery, setCommentsOfDelivery] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [pcodeOfInvoice, setPcodeOfInvoice] = useState("");
  const [townOfInvoice, setTownOfInvoice] = useState("");
  const [streetOfInvoice, setStreetOfInvoice] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [commentOfInvoice, setCommentOfInvoice] = useState("");
  const [bankcardIsChecked, setBankcardIsChecked] = useState(false);
  const [codIsChecked, setCodeIsChecked] = useState(false);
  const [showInvoiceForm, setShowInvoiceForm] = useState(false);
  const [cardNameFocus, setCardNameFocus] = useState(false);
  const [cardNumbFocus, setCardNumbFocus] = useState(false);
  const [cardDateFocus, setCardDateFocus] = useState(false);
  const [cardCvcFocus, setCardCvcFocus] = useState(false);
  const [deliveryPrice, setDeliveryPrice] = useState(390);
  const [invoice, setInvoice] = useState([]);
  const [client, setClient] = useState([]);

  //COUNT THE TOTAL PRICE OF THE ORDERLIST//
  let sumPriceList = props.location.state.orderedList.map(
    order => order.sumPrice
  );
  let sumOfOrderList = sumPriceList.reduce((a, b) => a + b, 0);

  let validate = (value, regex, message) => {
    if (!regex.test(value) && value != "") {
      alert(message);
    }
  };

  let sendData = () => {
    let newInvoice = [...invoice];
    newInvoice = {
      companyName: companyName,
      pcodeOfInvoice: pcodeOfInvoice,
      townOfInvoice: townOfInvoice,
      streetOfInvoice: streetOfInvoice,
      vatNumber: vatNumber,
      commentOfInvoice: commentOfInvoice
    };
    invoice.push(newInvoice);

    //CREATING THE NEW CLIENT FOR DELIVERY//
    let newClient = [...client];
    newClient = {
      netTotal: sumOfOrderList,
      deliveryPrice: deliveryPrice,
      superTotal: sumOfOrderList + deliveryPrice,
      lastName: lastName,
      firstName: firstName,
      phone: phone,
      email: email,
      postalCode: pcodeOfDelivery,
      address: streetOfDelivery,
      commentsOfDelivery: commentsOfDelivery,
      invoiceData: invoice,
      clientCart: props.location.state.orderedList
    };
    client.push(newClient);
  };

  let GetBillingAddress = event => {
    !showInvoiceForm ? setShowInvoiceForm(true) : setShowInvoiceForm(false);
  };

  let getBankCardDetails = () => {
    setBankcardIsChecked(true);
    setCodeIsChecked(false);
  };
  let getPayCash = () => {
    setCodeIsChecked(true);
    setBankcardIsChecked(false);
  };

  return (
    <div style={{ position: "absolute" }}>
      <NavBar />
      <div
        className="form-container p-d-flex p-jc-center"
        style={{ width: "100%", height: "220vh" }}
      >
        <div
          className="finalLeft p-flex-column p-shadow-4  p-p-4"
          style={{ width: "40%", height: "fit-content" }}
        >
          <div className="p-d-flex p-jc-start p-ai-center p-mb-5">
            <div className="p-d-flex p-jc-center p-ai-center p-mr-4">
              <h5>1</h5>
            </div>
            <h5>SZEMÉLYES ADATOK</h5>
          </div>
          <div
            className="p-fluid p-formgrid p-grid"
            style={{
              lineHeight: "70%"
            }}
          >
            <div className="finalPersonalData p-field p-col-12 p-md-6">
              <label htmlFor="lastname">Családnév</label>
              <InputText
                id="lastname"
                type="text"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                onBlur={e =>
                  validate(
                    e.target.value,
                    /[A-Za-z]{3}[A-Za-z]+/,
                    "Minimum 3 betűből állhat!!"
                  )}
              />
            </div>
            <div className="finalPersonalData p-field p-col-12 p-md-6">
              <label htmlFor="firstname">Keresztnév</label>
              <InputText
                id="firstname"
                type="text"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                onBlur={e =>
                  validate(
                    e.target.value,
                    /[A-Za-z]{3}[A-Za-z]+/,
                    "Minimum 3 betűből állhat!!"
                  )}
              />
            </div>
            <div className="finalPersonalData p-field p-col-12 p-md-2">
              <label htmlFor="zip">IRSZ</label>
              <InputText
                id="zip"
                type="text"
                value={pcodeOfDelivery}
                onChange={e => setPcodeOfDelivery(e.target.value)}
                onBlur={e =>
                  validate(
                    e.target.value,
                    /[0-9]{4}/,
                    "Pontosan 4 számjegy legyen!"
                  )}
              />
            </div>
            <div className="finalPersonalData p-field p-col-12 p-md-8">
              <label htmlFor="town">Város</label>
              <InputText
                id="town"
                type="text"
                value={townOfDelivery}
                onChange={e => setTownOfDelivery(e.target.value)}
                onBlur={e =>
                  validate(
                    e.target.value,
                    /[A-Za-z]+/,
                    "Csak betűkből állhat!"
                  )}
              />
            </div>
            <div className="finalPersonalData p-field p-col-12 p-md-8">
              <label htmlFor="address">Utca és házszám</label>
              <InputText
                id="address"
                type="text"
                rows="4"
                value={streetOfDelivery}
                onChange={e => setStreetOfDelivery(e.target.value)}
                onBlur={e =>
                  validate(
                    e.target.value,
                    /[A-Za-z]+\s+[1-9]+/,
                    "Az utca csak betű lehet, a házszám csak szám!"
                  )}
              />
            </div>
            <div className="finalPersonalData p-field p-col-12 p-md-5">
              <label htmlFor="email">Email</label>
              <InputText
                id="email"
                type="text"
                value={email}
                onChange={e => setEmail(e.target.value)}
                onBlur={e =>
                  validate(
                    e.target.value,
                    /\S+@\S+\.\S+/,
                    "Nem helyes az email cím!"
                  )}
              />
            </div>
            <div className="finalPersonalData p-field p-col-12 p-md-3">
              <label htmlFor="address">Mobiltelefon</label>
              <InputText
                id="phone"
                value={phone}
                onChange={e => setPhone(e.target.value)}
                onBlur={e =>
                  validate(
                    e.target.value,
                    /\+\d{2}-\d{2}-\d{3}-\d{4}/,
                    "Rossz a formátum: +36-00-000-0000 !"
                  )}
              />
            </div>
            <div className="finalPersonalData p-field p-col-12">
              <label htmlFor="address">Egyéb kérés vagy megjegyzés</label>
              <InputTextarea
                id="comment"
                type="text"
                rows="4"
                value={commentsOfDelivery}
                onChange={e => setCommentsOfDelivery(e.target.value)}
              />
            </div>
          </div>
          <div
            className="p-d-flex p-ai-center"
            style={{
              width: "100%"
            }}
          >
            <label className="checkBoxContainer">
              <input
                type="checkbox"
                checked={showInvoiceForm}
                name="radio1"
                onChange={e => GetBillingAddress(e)}
              />
              <span className="checkmark" />
              Számlázási cím
              <span id="invoiceAddressIsOther">
                - ha eltért a szállítás címtől
              </span>
            </label>
          </div>
          {showInvoiceForm &&
            <div
              className="p-fluid p-formgrid p-grid p-mt-4"
              style={{
                lineHeight: "70%"
              }}
            >
              <div className="finalPersonalData p-field p-col-12">
                <label htmlFor="firstname6">Cégnév</label>
                <InputText
                  id="firstname6"
                  type="text"
                  value={companyName}
                  onChange={e => setCompanyName(e.target.value)}
                  onBlur={e =>
                    validate(
                      e.target.value,
                      /[A-Za-z]{3}[A-Za-z]+/,
                      "Minimum 3 betűből állhat!!"
                    )}
                />
              </div>

              <div className="finalPersonalData p-field p-col-12 p-md-2">
                <label htmlFor="zip">IRSZ</label>
                <InputText
                  id="zip"
                  type="text"
                  value={pcodeOfInvoice}
                  onChange={e => setPcodeOfInvoice(e.target.value)}
                  onBlur={e =>
                    validate(
                      e.target.value,
                      /[0-9]{4}/,
                      "Pontosan 4 számjegy legyen!"
                    )}
                />
              </div>
              <div className="finalPersonalData p-field p-col-12 p-md-6">
                <label htmlFor="townInvoice">Város</label>
                <InputText
                  id="townInvoice"
                  type="text"
                  value={townOfInvoice}
                  onChange={e => setTownOfInvoice(e.target.value)}
                  onBlur={e =>
                    validate(e.target.value, /[a-z]+/, "Csak betűkből állhat!")}
                />
              </div>
              <div className="finalPersonalData p-field p-col-12">
                <label htmlFor="addressInvoice">Utca és házszám</label>
                <InputText
                  id="addressInvoice"
                  type="text"
                  value={streetOfInvoice}
                  onChange={e => setStreetOfInvoice(e.target.value)}
                  onBlur={e =>
                    validate(
                      e.target.value,
                      /[A-Za-z]+\s+[1-9]+/,
                      "Az utca csak betű lehet, a házszám csak szám!"
                    )}
                />
              </div>
              <div className="finalPersonalData p-field p-col-12">
                <label htmlFor="taxnumber">Adószám</label>
                <InputText
                  id="taxnumber"
                  value={vatNumber}
                  onChange={e => setVatNumber(e.target.value)}
                  onBlur={e =>
                    validate(
                      e.target.value,
                      /[0-9]{8}-[1-9]-[1-9]{2}/,
                      "Nem jó formátum: 12345678-1-12 !"
                    )}
                />
              </div>
              <div className="finalPersonalData p-field p-col-12">
                <label htmlFor="commentInvoice">
                  Egyéb kérés vagy megjegyzés
                </label>
                <InputTextarea
                  id="commentInvoice"
                  rows="4"
                  value={commentOfInvoice}
                  onChange={e => setCommentOfInvoice(e.target.value)}
                />
              </div>
            </div>}
          <div
            className="payment-continer p-mt-5"
            style={{ width: "100%", height: "100%" }}
          >
            {" "}<div className="p-d-flex p-jc-start p-ai-center">
              <div
                className="p-d-flex p-jc-center p-ai-center p-mr-4"
                style={{
                  width: "50px",
                  height: "50px",
                }}
              >
                <h5>2</h5>
              </div>
              <h5>FIZETÉSI MÓDOK</h5>
            </div>
            <div
              className="p-d-flex p-flex-column p-mt-5"
              style={{ width: "100%" }}
            >
              <div
                className="p-d-flex p-jc-between p-ai-center p-mb-3"
                style={{
                  width: "98%",
                  height: "100%"
                }}
              >
                <label className="checkBoxContainer">
                  <input
                    type="radio"
                    checked={bankcardIsChecked}
                    name="radio1"
                    onChange={e => getBankCardDetails(e)}
                  />
                  <span className="checkmark" />
                  Bankkártya
                </label>
                <div className="p-d-flex p-jc-between" style={{ width: "20%" }}>
                  <FaCcVisa style={{ fontSize: "2rem" }} />
                  <FaCcMastercard style={{ fontSize: "2rem" }} />
                  <FaCcAmex style={{ fontSize: "2rem" }} />
                </div>
              </div>
              {bankcardIsChecked &&
                <div
                  className="p-fluid p-formgrid p-grid"
                  style={{
                    lineHeight: "70%",
                    width: "100%"
                  }}
                >
                  <div className="p-field p-col-12">
                    <fieldset
                      className="card-fieldset"
                      style={{
                        border: cardNameFocus
                          ? "1px solid #1e1c1c"
                          : "1px solid rgb(189, 187, 187)"
                      }}
                    >
                      <legend
                        style={{
                          color: cardNameFocus ? "#1e1c1c" : "rgb(95, 94, 94)"
                        }}
                      >
                        Kártyán szereplő név
                      </legend>
                      <input
                        className="cardNumb-input"
                        type="text"
                        onFocus={() => setCardNameFocus(true)}
                        onBlur={() => setCardNameFocus(false)}
                      />
                    </fieldset>
                  </div>
                  <div className="p-field p-col-12">
                    <fieldset
                      className="card-fieldset"
                      style={{
                        border: cardNumbFocus
                          ? "1px solid #1e1c1c"
                          : "1px solid rgb(189, 187, 187)"
                      }}
                    >
                      <legend
                        style={{
                          color: cardNumbFocus ? "#1e1c1c" : "rgb(95, 94, 94)"
                        }}
                      >
                        Kártyaszám
                      </legend>
                      <input
                        className="cardNumb-input"
                        type="text"
                        onFocus={() => setCardNumbFocus(true)}
                        onBlur={() => setCardNumbFocus(false)}
                      />
                    </fieldset>
                  </div>
                  <div className="p-field p-col-12 p-md-6">
                    <fieldset
                      className="card-fieldset"
                      style={{
                        border: cardDateFocus
                          ? "1px solid #1e1c1c"
                          : "1px solid rgb(189, 187, 187)"
                      }}
                    >
                      <legend
                        style={{
                          color: cardDateFocus ? "#1e1c1c" : "rgb(95, 94, 94)"
                        }}
                      >
                        HH/EE
                      </legend>
                      <input
                        className="cardValid-input"
                        type="text"
                        onFocus={() => setCardDateFocus(true)}
                        onBlur={() => setCardDateFocus(false)}
                      />
                    </fieldset>
                  </div>
                  <div className="p-field p-col-12 p-md-6">
                    <fieldset
                      className="card-fieldset"
                      style={{
                        border: cardCvcFocus
                          ? "1px solid #1e1c1c"
                          : "1px solid rgb(189, 187, 187)"
                      }}
                    >
                      <legend
                        style={{
                          color: cardCvcFocus ? "#1e1c1c" : "rgb(95, 94, 94)"
                        }}
                      >
                        CVC
                      </legend>
                      <input
                        className="cardValid-input cvc"
                        type="text"
                        onFocus={() => setCardCvcFocus(true)}
                        onBlur={() => setCardCvcFocus(false)}
                      />
                    </fieldset>
                  </div>
                </div>}
              <div className="p-d-flex p-mt-4">
                <label className="checkBoxContainer">
                  <input
                    type="radio"
                    checked={codIsChecked}
                    name="radio1"
                    onChange={e => getPayCash(e)}
                  />
                  <span className="checkmark" />
                  Utánvét
                </label>
              </div>
            </div>
            <div
              className="page-break p-d-flex p-mt-5"
              style={{
                width: "100%",
                color: "white",
                borderTop: "1px solid rgb(189, 187, 187)"
              }}
            >
              ssswdw
            </div>
            <div
              className="p-d-flex p-jc-center p-mt-5"
              style={{
                width: "100%"
              }}
            >
              <button className="buttonStyle" onClick={sendData}>
                MEGRENDELEM
              </button>
            </div>
          </div>
        </div>

        <div
          className="finalRight p-d-flex p-flex-column p-ai-center p-shadow-2 p-ml-4"
          style={{ width: "30%", height: "80vh" }}
        >
          <div className="p-d-flex p-jc-center p-ai-center">
            <h5>AZ ÖN RENDELÉSE</h5>
          </div>
          {props.location.state.orderedList.map((item, index) =>
            <div
              key={index}
              className="p-d-flex p-flex-column p-jc-center p-ai-center"
              style={{
                width: "100%"
              }}
            >
              {item.counterWhole !== 0 &&
                <div
                  id="finalWholeContainer"
                  className="p-d-flex p-jc-around "
                  style={{ width: "100%" }}
                >
                  <p className="finalItems">
                    {item.counterWhole} X
                  </p>
                  <p className="finalItems" id="finalWholePizzaName">
                    {item.name}
                  </p>
                  {item.counterSlice !== 0
                    ? <p className="finalItems" id="finalWholePizzaPrice">
                        {item.sumPrice - item.slicePrice} Ft
                      </p>
                    : <p className="finalItems" id="finalWholePizzaSumPrice">
                        {item.sumPrice} Ft
                      </p>}
                </div>}
              <div
                className="p-d-flex p-flex-column p-ai-start"
                style={{ width: "30%" }}
              >
                {item.extras.length !== 0 &&
                  item.extras.map((extra, indexExt) =>
                    <p
                      key={indexExt}
                      className="finalItems"
                      id="finalExtraName"
                    >
                      + {extra.name}
                    </p>
                  )}
                {item.sides.length !== 0 &&
                  item.sides.map((side, indexSid) =>
                    <p
                      key={indexSid}
                      className="finalItems"
                      id="finalExtraName"
                    >
                      + {side.name}
                    </p>
                  )}
              </div>
              {item.description &&
                <p className="finalItems" id="finalExtraName">
                  * {item.description}
                </p>}
              {item.counterSlice !== 0 &&
                <div
                  className="details p-d-flex p-jc-around p-ai-center "
                  style={{ width: "95%" }}
                >
                  <p className="finalItems" id="finalSlicePizzaName">
                    {item.counterSlice} X
                  </p>
                  <p className="finalItems" id="finalSlicePizzaName">
                    {item.name} szelet
                  </p>
                  <p className="finalItems" id="finalSlicePizzaPrice">
                    {item.slicePrice} Ft
                  </p>
                </div>}
            </div>
          )}
          <div
            className="page-break p-d-flex p-mt-5"
            style={{
              width: "90%",
              color: "white",
              borderTop: "1px solid #ff4545"
            }}
          />
          <div className="p-d-flex p-flex-column" style={{ width: "80%" }}>
            <div className="p-d-flex p-jc-between" style={{ width: "100%" }}>
              <p className="finalItems">Bruttó összesen</p>
              <p className="finalItems" id="finalSumOfOrderedPrice">
                {sumOfOrderList} Ft
              </p>
            </div>
            <div
              className="p-d-flex p-jc-between"
              style={{
                width: "100%"
              }}
            >
              <p className="finalItems finalDelivery">Kiszállítási díj</p>
              <p className="finalItems finalDelivery">
                {deliveryPrice} Ft
              </p>
            </div>

            <div
              className="p-d-flex p-jc-between"
              style={{
                width: "100%"
              }}
            >
              <p className="finalItems" id="finalSumGrossPriceText">
                Bruttó mindösszesen
              </p>
              <p className="finalItems" id="finalSumGrossPrice">
                {sumOfOrderList + deliveryPrice} Ft
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Finalstep;
